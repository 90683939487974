<template>
  <div
    class="language-switcher"
    :class="{ 'language-switcher--light': light, 'language-switcher--large': large, 'language-switcher--slim': slim }"
    :style="getStyle"
  >
    <v-select
      v-model="value"
      :append-icon="slim ? 'mdi-chevron-down' : 'mdi-menu-down'"
      class="language-switcher__selector"
      background-color="transparent"
      dense
      :flat="slim"
      :solo="slim"
      :outlined="!slim"
      item-text="label"
      item-value="value"
      :items="langs"
      @change="handleLocale"
    />
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',

  props: {
    langs: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '96',
    },
    large: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: this.locale,
    };
  },

  computed: {
    getStyle() {
      return {
        width: this.slim ? `${this.width}px` : null,
      };
    },
  },

  methods: {
    handleLocale() {
      this.$emit('handle-locale', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-switcher {
  $--self: &;

  &--slim {
    ::v-deep #{$--self}__selector {
      font-size: rem(12);
      height: rem(32);

      .v-input__append-inner {
        padding-left: 0;
      }

      .v-input__icon {
        width: auto;
      }

      .v-input__slot {
        padding: 0 !important;
      }

      .v-input__append-inner .v-icon {
        font-size: rem(14);
      }

      .v-select__selection {
        margin: 0;
      }
    }
  }

  &--light {
    ::v-deep #{$--self}__selector {
      .v-select__selection,
      .v-input__append-inner .v-icon {
        color: white !important;
      }
    }
  }

  &--large {
    ::v-deep #{$--self}__selector {
      .v-select__selection {
        font-size: rem(18);
      }
    }
  }
}
</style>
