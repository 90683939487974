<template>
  <div class="copy-button">
    <span
      class="copy-button__button"
      :class="{
        active: isCopied,
        'copy-button__button--is-squared': squared,
        'copy-button__button--is-primary': primary,
      }"
      :style="getButtonStyles"
      :data-clipboard-target="elementToCopy"
    >
      <unicon :name="isCopied ? 'check' : 'copy-alt'" :fill="getIconFill" />
    </span>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { mapGetters } from 'vuex';
import scssColors from '@/sass/_variables.scss';

export default {
  name: 'CopyButton',

  props: {
    elementToCopy: {
      type: String,
      required: true,
    },
    useToast: {
      type: Boolean,
      default: false,
    },
    /* if I want the toast at the bottom */
    discreetToast: {
      type: Boolean,
      default: false,
    },
    squared: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    checkColor: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      clipboardInstance: null,
      isCopied: false,
      text: '',
      toastOptions: {
        x: this.discreetToast ? 'left' : 'center',
        y: this.discreetToast ? 'bottom' : 'top',
        timeout: 1500,
      },
      scssColors,
    };
  },

  computed: {
    ...mapGetters({
      styles: 'app/getAppStyles',
    }),

    getButtonStyles() {
      return {
        backgroundColor: this.primary ? this.styles.themePrimary : this.scssColors.neutralColor4,
      };
    },

    getIconFill() {
      return this.isCopied
        ? this.checkColor || this.$vuetify.theme.currentTheme.success
        : this.primary
        ? 'white'
        : this.styles.themePrimary;
    },
  },

  mounted() {
    this.createClipboardInstance();
  },

  methods: {
    createClipboardInstance() {
      this.clipboardInstance = new Clipboard('.copy-button__button');
      this.clipboardInstance.on('success', this.onSuccess);
      this.clipboardInstance.on('error', this.onError);
    },

    onSuccess(event) {
      this.useToast && this.$toast.success(this.$t('copyButton.onSuccess'), this.toastOptions);
      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
        event.clearSelection();
      }, 1500);
    },

    onError(e) {
      this.useToast && this.$toast.info(this.$t('copyButton.onError'), this.toastOptions);
      console.info('Action:', e.action);
      console.info('Trigger:', e.trigger);
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-button {
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: rem(40);
    height: rem(40);
    width: rem(40);
    font-size: rem(14);
    padding: rem(8);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid transparent;
    &--is-squared {
      border-radius: 0;
    }
  }
}
</style>
